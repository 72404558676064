.inputContainer::after {
  content: '';
  display: block;
  height: 1px;
  background-color: #e5e5e5;
}

.input {
  background-color: transparent;
  padding: 0.5rem 0;
  border: none;
  outline: none;
  width: 100%;
}