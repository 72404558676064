.button {
  background-color: var(--color-brand1);
  color: var(--color-white);
  padding: var(--spacing);
  min-width: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  transition: all 0.5s;
  border-radius: var(--border-radius-sm);
  cursor: pointer;
  font-size: var(--font-size);
  font-weight: var(--font-weight-medium);
  text-decoration: none;
}

.isDisabled {
  background-color: grey;
}

.cta {
  background-color: var(--color-brand1);
  color: var(--color-white);
}

.cta:hover,
.cta:focus {

}

.secondary {
  background-color: var(--color-brand2);
  color: var(--color-base1);
}

.secondary:hover,
.secondary:focus {
  background-color: var(--color-brand1);
  color: var(--color-white);
}

.outline {
  background-color: transparent;
  border: 2px solid var(--color-brand1);
  color: var(--color-brand1);
}

.outline:hover,
.outline:focus {
  background-color: var(--color-brand1);
  color: var(--color-white);
}

.link {
  background-color: transparent;
  border: none;
  color: var(--color-brand1);
  padding: 0;
  display: inline;
}

.link:hover,
.link:focus {
  text-decoration: underline;
}