.grid {
  display: grid;
  column-gap: var(--gutter-width);
  row-gap: var(--gutter-width);
  align-items: unset;
  grid-template-rows: auto;
}

.spacing {
  margin-bottom: var(--gutter-width);
}

.center {
  align-items: center;
}

.grid-cols-2,
.grid-cols-sm-2,
.grid-cols-md-2,
.grid-cols-lg-2 {
  grid-template-columns: 1fr 1fr;
}

.grid-cols-3,
.grid-cols-sm-3,
.grid-cols-md-3,
.grid-cols-lg-3 {
  grid-template-columns: 1fr 1fr 1fr;
}

.grid-cols-4,
.grid-cols-sm-4,
.grid-cols-md-4,
.grid-cols-lg-4 {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.grid-cols-5,
.grid-cols-sm-5
.grid-cols-md-5
.grid-cols-lg-5 {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.grid-cols-6,
.grid-cols-sm-6
.grid-cols-md-6
.grid-cols-lg-6 {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.grid-rows-2 {
  grid-template-rows: 1fr 1fr;
}