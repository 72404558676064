.card {
  padding: 1rem;
  display: flex;
  flex: 1;
  flex-direction: column;
  text-decoration: none;
  background-color: var(--color-white);
  box-shadow: rgba(0, 0, 0, 0.1) 0 5px 5px;
  color: var(--color-darkgrey);
}

a.card:hover {
  background-color: var(--color-lightgrey);
  text-decoration: none;
}

a.card:hover img {
  transform: scale(1.2);
}

.title {
  margin-bottom: 1rem;
}

.image {
  margin: -1rem -1rem 1rem;
  height: 15rem;
  position: relative;
  overflow: hidden;
}

.image img {
  transform-origin: 50% 50%;
  transition: all 0.5s;
}

.readMore {
  margin-top: auto;
}