.link {
    background-color: transparent;
    border: none;
    color: var(--color-brand1);
    padding: 0;
    display: inline-block;
}

.link:hover,
.link:focus {
    text-decoration: underline;
}