.spacer {
  height: var(--spacing);
}

.xs {
  height: var(--spacing-xs);
}

.sm {
  height: var(--spacing-sm);
}

.md {
  height: var(--spacing-md);
}

.lg {
  height: var(--spacing-lg);
}

.xl {
  height: var(--spacing-xl);
}