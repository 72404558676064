.logo {
  font-size: 2rem;
  color: var(--color-brand1);
  min-height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.25s;
  width: 100%;
}

.logo:hover {
  text-decoration: none;
  font-size: 2.4rem;
}

.part1 {
  font-weight: var(--font-weight-bold);
}

.part2 {
  font-weight: var(--font-weight-light);
}

.large {
  font-size: 6rem;
}

.large:hover {
  font-size: 6rem;
}