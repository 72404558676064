.label {
  display: block;
  font-weight: 400;
}

.primary {
  color: var(--color-accent2);
}

.inactive {
  color: var(--color-inactive);
}