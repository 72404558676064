.showHide {
  position: relative;
  border-bottom: 1px solid var(--color-base1);
  margin-bottom: 1rem;
  padding: var(--spacing) 0;
}

.content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 1s;
}

.isOpen .content {
  max-height: 50rem;
  overflow: visible;
}

.label {
  cursor: pointer;
  outline: none;
  border: none;
  background-color: var(--color-base1);
  color: var(--color-white);
  font-size: 1rem;
  height: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2rem;
  position: absolute;
  bottom: -1.8rem;
  left: 50%;
  transform: translateX(-50%);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}