.heading {
  margin: 0;
}

.h1 {
  font-size: var(--font-size-xl);
  line-height: 1;
}

.h2 {
  font-size: var(--font-size-lg);
  line-height: 1;
}

.h3 {
  font-size: var(--font-size-md);
  line-height: 1;
}

.h4 {
  font-size: var(--font-size);
  line-height: 1;
}

.h5 {
  font-size: var(--font-size-sm);
  line-height: 1;
}

.h6 {
  font-size: var(--font-size-sm);
  line-height: 1;
}

.color-white {
  color: #fff;
}