.tag {
  padding: var(--spacing-xs) var(--spacing-sm);
  border: 1px solid var(--color-base2);
  border-radius: var(--border-radius-xs);
  color: inherit;
  text-decoration: none;
}

a.tag:hover {
  background-color: var(--color-brand2);
}

.info {
  background-color: var(--color-brand2);
}